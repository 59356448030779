import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Form, Input, message, Row} from "antd";

const Config: React.FC = () => {
  const [config, setConfig] = useState<Record<string, string>>({});
  const [userInfo, setUserInfo] = useState<Record<string, string>>({});
  const [cardNo, setCardNo] = useState('');
  const [cardPass, setCardPass] = useState('');

  const updateUserInfo = () => {
    fetch('/login/info', {
      method: 'GET',
    }).then(res => res.json()).then((e) => {
      console.info(e)
      if (e.isSuccess) {
        setConfig(JSON.parse(e.detail?.config || '{}'));
        console.info('data is ', e.detail);
        setUserInfo(e.detail);
      }
    })
  }

  useEffect(() => {
    updateUserInfo();
  }, [])

  const [saving, setSaving] = useState(false)

  const handleSave = () => {
    console.info('handleSave', config)
    setSaving(true);
    fetch('/login/active', {
      method: 'POST',
      body: JSON.stringify({
        cardNo,
        cardPass
      }),
      headers: {
        'Content-Type': 'application/json;charset=utf8'
      }
    }).then(res => res.json()).then((data) => {
      console.info('flag is', data);
      if (data.success || data.isSuccess) {
        message.info('激活成功，请刷新页面')
        updateUserInfo();
      } else {
        message.error(data.message || "激活失败")
      }
    }).finally(() => {
      setSaving(false);
    })
  }

  console.info('user is', userInfo);
  if (userInfo.isActiveVip) {
    const text = `您已经是 VIP 了，可以尽情使用。过期时间： ${userInfo.vipEndTime || '-'}`;
    return (
      <>
        <Alert message={text} type="success"/>
      </>
    )
  }

  return (
    <>
      <Form>
        <Row gutter={16}>
          <Col span={8}>
            <Card title="一天会员 5 块钱">
              <a href={"https://c.fakamiao.top/shopDetail/Wte5t6"}
                 target={"_blank"}
                 rel="noreferrer">购买链接：https://c.fakamiao.top/shopDetail/Wte5t6</a>
              <a href={"https://www.zhufaka.cn/shop/R8QB4K01"} style={{color: 'red'}}
                 target={"_blank"}
                 rel="noreferrer">备用店铺：https://www.zhufaka.cn/shop/R8QB4K01</a>
              <a
                href={"https://z.wiki/autoupload/20241112/bdrU/2558X1736/image.png?type=asdf&x-oss-process=image/auto-orient,1/interlace,1/quality,Q_50/contrast,-8/watermark,text_ei53aWtp,color_0c0c0c,size_25,g_se,x_20,y_20"}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{maxWidth: "300px"}}
                  src={"https://z.wiki/autoupload/20241112/bdrU/2558X1736/image.png?type=asdf&x-oss-process=image/auto-orient,1/interlace,1/quality,Q_50/contrast,-8/watermark,text_ei53aWtp,color_0c0c0c,size_25,g_se,x_20,y_20"}
                  alt={"购买教程"}
                />
              </a>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="一个月会员 20 块钱">
              <a href={"https://c.fakamiao.top/shopDetail/Wte5t6"}
                 target={"_blank"}
                 rel="noreferrer">一个月会员-购买链接：https://c.fakamiao.top/shopDetail/Wte5t6</a>
            </Card>
            <br/>
            <Card title="一周会员 10 块钱">
              <a href={"https://c.fakamiao.top/shopDetail/Wte5t6"}
                 target={"_blank"}
                 rel="noreferrer">一周会员-购买链接：https://c.fakamiao.top/shopDetail/Wte5t6</a>
            </Card>
            <Card title="一年会员 60 元，无法购买？加博主微信！">
              <img style={{width: "150px"}} src={"https://cdn.z.wiki/autoupload/20241217/W2xb/630X678/image.png"}/>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="购买后获取卡号密码">
              <a target={"_blank"}
                 rel="noreferrer"
                 href={"https://cdn.z.wiki/autoupload/20241017/9UVf/744X1614/image.png"}>
                点击放大查看
                <img src={"https://cdn.z.wiki/autoupload/20241017/9UVf/744X1614/image.png"}
                     alt="卡号密码获取说明"
                     style={{maxWidth: '50%', margin: '0 auto'}}/>
              </a>
            </Card>
          </Col>
        </Row>
        <br/>
        <Alert type={'info'} message={"请购买VIP后输入卡号密码激活VIP，支付后的界面中有卡号和密码"}/>
        <br/>
        <Form.Item label="卡号">
          <Input placeholder="请输入卡号" value={cardNo}
                 onChange={(e) => setCardNo(e.target.value)}/>
        </Form.Item>
        <Form.Item label="密码">
          <Input placeholder="请输入密码" value={cardPass}
                 onChange={(e) => setCardPass(e.target.value)}/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={saving} onClick={handleSave}>激活会员</Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Config;
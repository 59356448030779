/**
 * 替换 URL 中的域名
 * @param url - 原始 URL
 * @param newDomain - 新的域名（不带协议）
 * @returns 替换域名后的新 URL
 */
export function replaceDomain(url: string, newDomain: string): string {
  try {
    // 解析 URL
    const parsedUrl = new URL(url);

    // 替换域名
    parsedUrl.hostname = newDomain;

    return parsedUrl.toString();
  } catch (error) {
    console.error("Invalid URL provided:", error);
    throw new Error("Invalid URL");
  }
}
import './index.css'
import UploadItem from "../UploadItem";
import {Divider, message, Modal, Pagination, Spin, Upload, UploadProps} from 'antd';
import React, {useEffect, useState} from "react";
import {getId, postFile} from '../../helpers/file';
import RightLink from "./RightLink";

const { Dragger } = Upload;

const UPLOAD_KEY = 'upload_key';

/**
 *
 * @author dongdong.fdd
 * @Company AutoNavi Co.,Ltd.
 * @date 2023/7/29 15:26
 */
function Body() {
  const [uuid, setUuid] = useState<string | null>();
  const [list, setList] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<Record<string, string>>({});


  const updateUserInfo = () => {
    fetch('/login/info', {
      method: 'GET',
    }).then(res => res.json()).then((e) => {
      if (e.isSuccess) {
        const _info = e.detail;
        if (_info.config && typeof _info.config === 'string') {
          try {
            _info.config = JSON.parse(_info.config);
          } catch (e) {
            console.error('config is not json string', _info.config);
          }
        }
        setUserInfo(_info);
      }
    })
  }

  useEffect(() => {
    updateUserInfo();
  }, [])


  useEffect(() => {
    setUuid(getId());
  }, [])

  // 加载历史数据
  const loadHistory = () => {
    setLoading(true);
    fetch(`/img/history?uid=${getId()}&page=${current}&pageSize=${pageSize}`).then(res => res.json()).then(pageData => {
      console.info('pageData is', pageData);
      setLoading(false);
      setList(pageData.data);
      setTotal(pageData.total);
    })
  }

  useEffect(() => {
    const handlePaste = async (event: any) => {
      if(event.target.tagName == 'INPUT' && event.target.type == 'text') {
        return;
      }
      const items = (event.clipboardData).items || [];
      message.info({
        content: `开始上传`,
        key: UPLOAD_KEY
      });
      console.info('items is', items);
      if(items.length > 1 && items[0].kind != 'string') {
        // 选择了多个文件
        const files = [...items].map((item: any) => {
          return item.getAsFile();
        })
        for(const file of files) {
          await new Promise(async (resolve) => {
            if(file) {
              try {
                const result = await postFile(file, (percent) => {
                  let content = `上传进度：${percent}%`;
                  if (percent == 100) {
                    content = '上传完成，后台校验中...'
                  }
                  message.info({
                    duration: 1000,
                    content,
                    key: UPLOAD_KEY
                  });
                }).catch(() => {});
                console.info('upload result is ', result);
                resolve(true);
              } catch(e) {
                console.error(e);
                resolve(true);
              }
            }
          })
        }
        message.success({
          content: `上传成功`,
          key: UPLOAD_KEY
        });
        loadHistory();
      } else {
        for(const item of items) {
          console.info('item is ', item, items.length);
          await new Promise(async (resolve) => {
            const file = item && item.getAsFile();
            if(file) {
              try {
                const result = await postFile(file, (percent) => {
                  let content = `上传进度：${percent}%`;
                  if (percent == 100) {
                    content = '上传完成，后台校验中...'
                  }
                  message.info({
                    duration: 1000,
                    content,
                    key: UPLOAD_KEY
                  });
                }).catch(() => {});
                console.info('upload result is ', result);
                resolve(true);
              } catch(e) {
                console.error(e);
                resolve(true);
              }
            } else {
              // 尝试读取剪切板中的内容
              if(item.type.match('^text/plain')) {
                item.getAsString(async (clipboardContent: any) => {
                  const blob = await new Blob([clipboardContent]);
                  const file = new File([blob], '来自剪切板的文本.txt', {
                    type : "text/plain;charset=utf-8"
                  });
                  await postFile(file, (percent) => {
                    let content = `上传进度：${percent}%`;
                    if (percent == 100) {
                      content = '上传完成，后台校验中...'
                    }
                    message.info({
                      duration: 1000,
                      content,
                      key: UPLOAD_KEY
                    });
                  })
                  resolve(true);
                })
              } else {
                console.info('not found file')
                message.warning('剪切板中未找到任何文件。')
                resolve(true);
              }
            }
          })
          console.info('upload done', items.length);
        }
        message.success({
          content: `上传成功`,
          key: UPLOAD_KEY
        });
        loadHistory()
      }
    }

    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('paste', handlePaste);
    }
  }, [])

  useEffect(() => {
    loadHistory();
  }, [uuid, current, pageSize])

  const props: UploadProps = {
    showUploadList: false,
    name: 'file',
    multiple: true,
    action: '/img/upload',
    data: (file) => {
      message.info({
        content: `开始上传`,
        key: UPLOAD_KEY
      });
      return {
        fileName: file.name,
        uid: getId(),
      }
    },
    onChange(info) {
      const { status} = info.file;
      const event = info.event;
      console.info('status is', status, event?.percent);
      if (status == 'uploading') {
        let content = `上传进度：${event?.percent?.toFixed?.(0)}%`;
        if (event?.percent?.toFixed?.(0) == '100') {
          content = '上传完成，后台校验中...'
        }
        message.info({
          duration: 1000,
          content,
          key: UPLOAD_KEY
        });
      }
      if (status === 'done') {
        message.success({
          content:`${info.file.name} 上传成功`,
          key: UPLOAD_KEY
        });
        loadHistory();
      } else if (status === 'error') {
        message.error(
          {
            content: `${info.file.name} 上传失败`,
            key: UPLOAD_KEY
          }
        );
        const res = info.file.response;
        // file.response.success
        if ((res as any).success == false) {
          Modal.error(
            {
              // content: `${info.file.name} 上传失败, 原因如下： ${(res as any).message}`,
              content: (
                <div>
                  <span>{info.file.name} 上传失败</span>
                  <Divider orientation={'left'}><span style={{fontSize: '12px'}}>详细原因如下:</span></Divider>
                  <span>{(res as any).message}</span>
                  <Divider orientation={'left'}><span style={{fontSize: '12px'}}>自行购买VIP:</span></Divider>
                  <span
                    style={{color: 'red'}}>
                    1. <a href={"https://c.fakamiao.top/shopDetail/Wte5t6"} target={'_blank'}>点击购买会员</a> <a
                    href={"https://www.zhufaka.cn/shop/R8QB4K01"} target={'_blank'}>备用地址</a>
                    2. 付款获得会员卡号+密码
                    3. 返回 敖武的图床 登录
                    4. 激活会员
                    5. 使用
                  </span>
                  <Divider orientation={'left'}><span style={{fontSize: '12px'}}>更多技术支持:</span></Divider>
                  <img style={{margin: '0 auto', display: 'block'}}
                       src={"https://z.wiki/autoupload/20241011/HSOd/506X598/image.png"} width={'257px'}
                       height={'257px'}/>
                </div>
              ),
              title: '上传失败',
            }
          );
        }
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <div
      className="Body"
      style={{
        paddingLeft: '30px',
        paddingRight: '30px',
      }}
    >
      <h1
        title={"敖武的图床 - 免费图片上传, 专业图片外链, 免费公共图床"}
        style={{
          "height": "28px",
          "fontFamily": "PingFangSC",
          "fontWeight": 500,
          "fontSize": "20px",
          "color": "#21242E",
          "lineHeight": "28px",
          margin: '0px',
          marginTop: '20px',
          marginBottom: '12px',
        }}
      >
        敖武的图床
        <span
          title="点此联系博主进行功能定制"
          style={{
            "display": "inline-flex",
            "alignItems": "center",
            "fontSize": "12px",
            "color": "#757d8b",
            "cursor": "pointer",
            "paddingLeft": "5px",
            "lineHeight": "14px"
          }}
          onClick={() => {
            Modal.info({
              title: '联系博主',
              content: (
                <div
                  className={"qr-code-ctn"}
                  style={{
                    marginRight: '34px'
                  }}
                >
                  <p className={"mailto-url"}>
                    <a href="mailto:fudongdong@fudongdong.com" target={'_blank'}>邮箱：fudongdong@fudongdong.com</a>
                  </p>
                  <div className="qr-code">
                    <img src={"https://z.wiki/autoupload/20231209/2Vsb.1164X1122-IMG_1279.JPG"} width={"100%"}/>
                  </div>
                </div>
              )
            })
          }}
        >
          功能定制
          <img
            src="https://cdn.z.wiki/autoupload/20240321/LtjP.question-circle.svg"
            style={{
              "height": "14px",
              "paddingLeft": "3px"
            }}
          />
        </span>
        <span
          title="获取身份唯一标识"
          style={{
            "display": "inline-flex",
            "alignItems": "center",
            "fontSize": "12px",
            "color": "#757d8b",
            "cursor": "pointer",
            "paddingLeft": "5px",
            "lineHeight": "14px"
          }}
          onClick={() => {
            Modal.info({
              title: '身份唯一标识',
              content: (
                <div
                  className={"qr-code-ctn"}
                  style={{
                    marginRight: '34px'
                  }}
                >
                  <p className={"mailto-url"}>
                    <span>请截图给网站管理员：微信：16619779404</span>
                  </p>
                  <div className="qr-code">
                    <img src={"https://playground.z.wiki/qrcode/generateQRCode?data=" + getId() + " " + document.cookie}
                         width={"100%"}/>
                  </div>
                </div>
              )
            })
          }}
        >
          身份
        </span>

      </h1>
      <div
        className="opts"
        style={{
          paddingBottom: '16px'
        }}
      >
        <div className="get-share-file">

        </div>
        <div className="links">

        </div>
        <div className="auto-copy">

        </div>
        <div
          className="tips"
          style={{
            color: '#757D8B',
            fontSize: '12px',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div className="left">
            复制图片，打开页面并按下<span className={"cmd"}>Ctrl+V</span>或<span className="cmd">Command+V</span>就能自动上传照片啦~
            <a href={"https://support.qq.com/product/659406"} target={'_blank'} style={{color: 'red'}}>功能反馈</a>
          </div>
          <div className="right" style={{flexShrink: 0}}>
            <RightLink/>
          </div>
        </div>
      </div>

      <div
        className="upload-zone"
        style={{
          "height": "248px",
          "backgroundColor": "#FFFFFF",
          "borderRadius": "6px",
          padding: '24px',
        }}
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <img src={"https://z.wiki/autoupload/20230731/JQyP.ic_%E5%A2%9E%E5%8A%A0%E8%A1%A8%E6%A0%BC.svg"} />
          </p>
          <p
            className="ant-upload-text"
            style={{
              fontSize: '14px',
              margin: 0,
            }}
          >
            拖拽文件到此区域或<span style={{color: '#1A66FF'}}>点此上传</span>
          </p>
          <p
            className="ant-upload-hint"
            style={{
              fontSize: '12px',
              margin: 0,
              marginTop: '4px',
            }}
          >
            支持上传照片、视频、ZIP、pdf等多种文件，最大支持100M，上传后支持复制url、base64、Markdown 照片三种方式
          </p>
        </Dragger>
      </div>

      <br />


      <Spin spinning={loading}>
        <div
          className="list"
          style={{
            "display": "flex",
            "alignItems": "flex-start",
            "flexDirection": "row",
            "flexWrap": "wrap",
            "alignContent": "flex-start",
            justifyContent: "space-between",
          }}
        >
          {
            (
              <>
                {
                  list?.map((item, index) => {
                    console.info('item is', item);
                    return (
                      <UploadItem
                        index={index}
                        userInfo={userInfo}
                        key={item.id}
                        url={item.url}
                        rawUrl={item.rawUrl}
                        contentType={item.contentType}
                        id={item.id}
                        fileName={item.fileName}
                        time={item.time}
                        size={item.size}
                        onDelete={() => {
                          loadHistory();
                        }}
                      />
                    )
                  })
                }
                <i className={'hiddenUploadItem'} style={{width: '324px'}} />
                <i className={'hiddenUploadItem'} style={{width: '324px'}} />
                <i className={'hiddenUploadItem'} style={{width: '324px'}} />
                <i className={'hiddenUploadItem'} style={{width: '324px'}} />
                <i className={'hiddenUploadItem'} style={{width: '324px'}} />
                <i className={'hiddenUploadItem'} style={{width: '324px'}} />
                <i className={'hiddenUploadItem'} style={{width: '324px'}} />
                <i className={'hiddenUploadItem'} style={{width: '324px'}} />
                <i className={'hiddenUploadItem'} style={{width: '324px'}} />
              </>
            )
          }

        </div>
      </Spin>

      <div
        className="footer"
        style={{
          "display": "flex",
          "flexDirection": "row-reverse",
          "paddingBottom": "40px"
        }}
      >
        <Pagination
          current={current}
          size={'small'}
          showSizeChanger
          pageSizeOptions={['10', '20', '50']}
          total={total}
          showTotal={(total) => `共 ${total} 条数据`}
          pageSize={pageSize}
          onChange={(page, pageSize) => {
            setCurrent(page);
            setPageSize(pageSize);
          }}
        />
      </div>
    </div>
  );
}

Body.propTypes = {}

export default Body
